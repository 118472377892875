// extracted by mini-css-extract-plugin
export var aboutUsContainer = "AboutUs-module--aboutUsContainer--8475e";
export var aboutUsContent = "AboutUs-module--aboutUsContent--6ecb4";
export var aboutUsDetailContent = "AboutUs-module--aboutUsDetailContent--03b53";
export var aboutUsDetailContentText = "AboutUs-module--aboutUsDetailContentText--bbd67";
export var aboutUsDetailContentTitle = "AboutUs-module--aboutUsDetailContentTitle--6926f";
export var aboutUsImageSection = "AboutUs-module--aboutUsImageSection--0e1b0";
export var aboutUsTitle = "AboutUs-module--aboutUsTitle--f5fa4";
export var cultureItem = "AboutUs-module--cultureItem--f4a74";
export var cultureRow = "AboutUs-module--cultureRow--e4a72";
export var itemContent = "AboutUs-module--itemContent--b7e57";
export var itemContentText = "AboutUs-module--itemContentText--08be8";
export var itemContentTitle = "AboutUs-module--itemContentTitle--e7ac1";
export var itemIcon = "AboutUs-module--itemIcon--42e01";
export var mobileAboutUsTitle = "AboutUs-module--mobileAboutUsTitle--2db88";
export var mobileItemBorder = "AboutUs-module--mobileItemBorder--e0032";
export var mobileSubTitle = "AboutUs-module--mobileSubTitle--896a6";
export var mobileTitleColor = "AboutUs-module--mobileTitleColor--69f9b";