import React from 'react'
import * as styles from './../styles/Services.module.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

interface ServicesProps {
  services: Service[]
  isDetail?: boolean
}

const ServiceCard: React.FC<{ service: Service }> = ({ service }: { service: Service }) => {
  const image = getImage(service.serviceCover.localFile)
  return (
    <Link className={`${styles.serviceCard}`} to={`/services/${service.id}`}>
      <div className={styles.cardBackgroundCover} />
      <GatsbyImage className={styles.serviceCardBackground} image={image} alt="background"></GatsbyImage>
      <div className={styles.serviceCardTitle}>{service.serviceName}</div>
      <div className={styles.checkforDetail}>查看详情</div>
    </Link>
  )
}

const Services: React.FC<ServicesProps> = ({ services, isDetail = false }) => {
  const DISPLAY_LIMIT = 8
  const displayServices = isDetail ? services : services.slice(0, DISPLAY_LIMIT)
  return (
    <div className={styles.serviceContainer}>
      <div className={styles.serviceTitle}>我们的服务</div>
      <div className={styles.mobileServiceTitle}>Our Service</div>
      <div className={styles.serviceContent}>
        专业、多元、定制化的移民解决方案，在Canovo，我们深知每一条移民之路都是独一无二的。我们的使命是为您提供全面、个性化的移民服务，确保您的移民旅程顺畅无忧。凭借多年的行业经验和对政策的深入理解，我们团队的专家们能够为您量身打造最适合您需求的移民规划。我们承诺，无论您的目标是为了工作、学习还是家庭团聚，Canovo都将成为您最坚实的后盾。让我们共同打造您的成功移民故事。选择Canovo，让我们成为实现您加拿大梦想的桥梁。
      </div>
      <div className={styles.serviceCardList}>
        {displayServices.map((service) => {
          return <ServiceCard key={service.id} service={service} />
        })}
      </div>
      {!isDetail && (
        <Link className={styles.checkMore} to="/services">
          查看更多
        </Link>
      )}
    </div>
  )
}

export default Services
