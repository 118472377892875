// extracted by mini-css-extract-plugin
export var cardBackgroundCover = "Services-module--cardBackgroundCover--e3aff";
export var checkMore = "Services-module--checkMore--440c3";
export var checkforDetail = "Services-module--checkforDetail--b4983";
export var mobileServiceTitle = "Services-module--mobileServiceTitle--889d4";
export var serviceCard = "Services-module--serviceCard--4192e";
export var serviceCardBackground = "Services-module--serviceCardBackground--1dc91";
export var serviceCardList = "Services-module--serviceCardList--1f4a1";
export var serviceCardTitle = "Services-module--serviceCardTitle--81e10";
export var serviceContainer = "Services-module--serviceContainer--005a7";
export var serviceContent = "Services-module--serviceContent--6ccf2";
export var serviceTitle = "Services-module--serviceTitle--ec68b";