import * as React from 'react'

import LayoutRoot from '../components/LayoutRoot'
import Services from '../components/Services'
import AboutUs from '../components/AboutUs'
import ContactUs from '../components/ContactUs'
import SuccessCase from '../components/SuccessCase'

export interface SearchProps {
  pageContext: {
    services: Service[]
    successcases: SuccessCase[]
    homebanneritems: HomeBannerItem[]
  }
}

export interface SearchResult {
  id: string
  title: string
  url: string
  date: string
}

const Home = ({ pageContext: { services, successcases, homebanneritems } }: SearchProps) => {
  return (
    <LayoutRoot homeBannerItems={homebanneritems}>
      <>
        <Services services={services} />
        <div style={{ marginTop: 80 }} />
        <AboutUs />
        <div style={{ marginTop: 80 }} />
        <ContactUs />
        <div style={{ marginTop: 80 }} />
        <SuccessCase cases={successcases} />
      </>
    </LayoutRoot>
  )
}

export default Home
