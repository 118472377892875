import React from 'react'
import * as styles from './../styles/AboutUs.module.scss'

import CulturePowerIcon from '../icons/culture_power.inline.svg'
import CultureProIcon from '../icons/culture_pro.inline.svg'
import CultureTrustIcon from '../icons/culture_trust.inline.svg'

const AboutUs: React.FC<{ isDetail?: boolean }> = ({ isDetail = false }) => {
  return (
    <div className={styles.aboutUsContainer}>
      {!isDetail && <div className={styles.aboutUsTitle}>关于我们</div>}
      {!isDetail && <div className={styles.mobileAboutUsTitle}>About Us</div>}
      <div className={styles.aboutUsImageSection} />
      {!isDetail && (
        <div className={styles.aboutUsContent}>
          移 哪 儿 CANOVO移民隶属于新趋势集团， 由从事移民行业20余年的资深专家、中加两地 的资深移民律师和前签证官组成。具有丰富的
          项目操作经验和成功案例，以加拿大本地大量直 接资源为依托，为客户提供标准化的一站式服务 。 新趋势集团(NU STREAM)成立于2015年
          12月，总部位于加拿大温哥华，2016年设立多伦 多分公司。2017年小米、IDG等机构联手注资新 趋势集团，2018年知名加拿大房地产互联网平
          台——温房网并入新趋势。
        </div>
      )}
      {isDetail && (
        <div className={styles.aboutUsDetailContent}>
          <div className={`${styles.aboutUsDetailContentTitle} ${styles.mobileTitleColor}`}>关于移哪儿</div>
          <div className={styles.mobileSubTitle}>About Us</div>
          <div className={styles.aboutUsDetailContentText}>
            移哪儿Canovo，作为获得了IDG与小米投资的加拿大新趋势集团的全资子公司，总部设在加拿大温哥华，同时在多伦多、北京和上海等地设立了办公室。我们拥有超过20年的移民行业经验，并聚集了资深移民律师和教育行业专业人士组成的专家团队。我们提供加拿大、美国、欧洲、澳洲和亚洲等其他热门国家的移民项目服务。凭借丰富的项目运营经验和行业成功案例，移哪儿Canovo将充分利用资源，为客户提供标准化、全方位的项目服务。
          </div>
          <div className={`${styles.aboutUsDetailContentTitle} ${styles.mobileTitleColor}`}>公司文化</div>
          <div className={styles.mobileSubTitle}>Corporate Culture</div>
          <div className={styles.cultureRow}>
            <div className={styles.cultureItem}>
              <div className={styles.itemIcon}>
                <CultureProIcon />
              </div>
              <div className={styles.itemContent}>
                <div className={styles.itemContentTitle}>专业</div>
                <div className={styles.itemContentText}>
                  多名行业专家和持牌人士共同组成团队，为客户提供最专业、最贴心的企业出海、移民、教育及安家置业的一站式服务。
                </div>
              </div>
            </div>
            <div className={`${styles.cultureItem} ${styles.mobileItemBorder}`}>
              <div className={styles.itemIcon}>
                <CultureTrustIcon />
              </div>
              <div className={styles.itemContent}>
                <div className={styles.itemContentTitle}>诚信</div>
                <div className={styles.itemContentText}>公司始终秉承客户第一、信誉至上的经营理念，倡导以结果为导向，以客户满意为宗旨。</div>
              </div>
            </div>
            <div className={styles.cultureItem}>
              <div className={styles.itemIcon}>
                <CulturePowerIcon />
              </div>
              <div className={styles.itemContent}>
                <div className={styles.itemContentTitle}>实力</div>
                <div className={styles.itemContentText}>通过专业服务、资源整合及品牌 让客户完全可以信赖。</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AboutUs
